<template>
  <section style="height: 100%">
    <el-row
      :gutter="24"
      class="box-title"
      style="margin: 0 20px; height: 100%; overflow-y: visible"
    >
      <el-col :offset="1" :span="24" style="margin: 0; padding: 0">
        <el-tabs
          class="tabs-box setBar"
          @tab-click="handleClick"
          v-model="activeName"
        >
          <el-tab-pane label="基础信息" name="1">
            <el-form
              class="formFoo"
              @submit.native.prevent
              :model="userInfo"
              ref="userInfo"
              :rules="rules"
              label-width="130px"
            >
              <el-form-item>
                <el-upload
                  class="avatar-uploader"
                  :headers="myHeaders"
                  :action="actionUrl"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess"
                  :before-upload="beforeAvatarUpload"
                  accept="image/jpeg, image/jpg, image/png"
                >
                  <div class="avatarBack">修改头像</div>
                  <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <p class="setPicture">修改头像</p>
              </el-form-item>
              <el-form-item label="员工姓名：" prop="nickName">
                <el-input
                  disabled
                  v-model.trim="userInfo.nickName"
                  maxlength="15"
                ></el-input>
              </el-form-item>
              <el-form-item label="手机号码：" prop="mobile">
                <el-input
                  v-model.trim="userInfo.mobile"
                  maxlength="11"
                ></el-input>
              </el-form-item>
              <el-form-item class="item" label="默认首页：" prop="path">
                <el-select
                  v-model="userInfo.path"
                  style="width: 100%"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="(item, index) in optionsData"
                    :key="index"
                    :label="item.name"
                    :value="item.path"
                  ></el-option>
                </el-select>
                <p style="color: #999; font-size: 12px">
                  指登录后默认的落地页，可以设置成您最常用的页面
                </p>
              </el-form-item>
              <el-form-item>
                <el-button
                  type="primary"
                  size="mini"
                  :loading="saveloading"
                  @click="save()"
                  >提交</el-button
                >
              </el-form-item>
            </el-form>
          </el-tab-pane>
          <el-tab-pane label="修改密码" name="2">
            <el-form
              class="formFoo"
              :model="passwordInfo"
              ref="passwordInfo"
              :rules="passwordInfoRules"
              label-width="120px"
            >
              <el-form-item label="旧密码：" prop="oldPassword">
                <el-input v-model.trim="passwordInfo.oldPassword"></el-input>
              </el-form-item>
              <el-form-item label="新密码：" prop="newPasswordFirst">
                <el-input
                  v-model.trim="passwordInfo.newPasswordFirst"
                ></el-input>
              </el-form-item>
              <el-form-item label="确认密码：" prop="newPasswordSecond">
                <el-input
                  v-model.trim="passwordInfo.newPasswordSecond"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-button
                  type="primary"
                  size="mini"
                  :loading="saveloading"
                  @click="savePassword()"
                  >提交</el-button
                >
              </el-form-item>
            </el-form>
          </el-tab-pane>
        </el-tabs>
      </el-col>
    </el-row>
  </section>
</template>
<script>
import {
  getCurrentAccount,
  updateCurrentAccount,
  updateAccountPassword,
  sysUserIndexOptions, //获取当前用户可设置的主页列表
} from "../../api/api";
import { mapGetters } from "vuex";
import { ApiBaseUrl } from "../../api/http";
import Cookies from "js-cookie";

export default {
  computed: {
    // 使用对象展开运算符将 getters 混入 computed 对象中
    ...mapGetters([
      "upDateAvatar",
      "upDateUserName",
      "upDateMobile",
      // ...
    ]),
  },
  data() {
    const validateTel = (rules, value, callback) => {
      if (!Number.isInteger(Number(value))) {
        callback(new Error("请输入正确的手机号"));
      }
      callback();
    };
    return {
      activeName: "1",
      actionUrl: ApiBaseUrl + "/api/file/uploadOne",
      baseUrl: ApiBaseUrl,
      myHeaders: {
        Authorization: Cookies.get("accessToken"),
      },
      rules: {
        nickName: [
          {
            required: true,
            trigger: "blur",
            message: "请输入员工姓名",
          },
        ],
        mobile: [
          {
            validator: validateTel,
          },
        ],
      },
      passwordInfoRules: {
        oldPassword: [
          {
            required: true,
            trigger: "blur",
            message: "请输入旧密码",
          },
        ],
        newPasswordFirst: [
          {
            required: true,
            trigger: "blur",
            message: "请输入新密码",
          },
        ],
        newPasswordSecond: [
          {
            required: true,
            trigger: "blur",
            message: "请输入确认密码",
          },
        ],
      },
      emailInfoRules: {
        authCode: [
          {
            required: true,
            trigger: "blur",
            message: "请输入授权码",
          },
        ],
        signName: [
          {
            required: true,
            trigger: "blur",
            message: "请输入姓名",
          },
        ],
        signPosition: [
          {
            required: true,
            trigger: "blur",
            message: "请输入职位",
          },
        ],
        signMobile: [
          {
            required: true,
            trigger: "blur",
            message: "请输入手机号",
          },
        ],
        signEmail: [
          {
            required: true,
            trigger: "blur",
            message: "请输入邮箱",
          },
        ],
        signWebsite: [
          {
            required: true,
            trigger: "blur",
            message: "请输入网址",
          },
        ],
        signAddress: [
          {
            required: true,
            trigger: "blur",
            message: "请输入地址",
          },
        ],
      },
      userInfo: {
        avatar: "",
        id: 0,
        mobile: "",
        nickName: "",
        path: "",
      },
      imageUrl: "",
      saveloading: false,
      passwordInfo: {
        oldPassword: "",
        newPasswordFirst: "",
        newPasswordSecond: "",
      },
      avatarBack: false,
      optionsData: [],
    };
  },
  created() {
    this.getCurrentAccount();
    this.getSysUserIndexOptions();
  },
  destroyed: function () {
  },
  methods: {
    avatoronFocus() {
      this.$nextTick(() => {
        this.avatarBack = true;
      });
    },
    avatorMove() {
      this.avatarBack = false;
    },
    handleClick(tab, event) {
      this.$refs.passwordInfo.resetFields();
      this.$refs.userInfo.resetFields();
      this.imageUrl = null;
      switch (tab.name) {
        case "1":
          this.getCurrentAccount();
          this.getSysUserIndexOptions();
          break;
        case "3":
          this.getEmailInfo();
          break;
      }
    },
    save: function () {
      this.saveloading = false;
      this.$refs.userInfo.validate((valid) => {
        if (valid) {
          this.saveloading = true;
          updateCurrentAccount(this.userInfo).then((res) => {
            this.saveloading = false;
            if (res.success) {
              this.$message.success("保存成功");
              this.getCurrentAccount("true");
            }
          });
        }
      });
    },
    savePassword: function () {
      this.saveloading = false;
      this.$refs.passwordInfo.validate((valid) => {
        if (valid) {
          this.saveloading = true;
          this.passwordInfo.id = JSON.parse(localStorage.getItem("useInfo")).id;
          updateAccountPassword(this.passwordInfo).then((res) => {
            this.saveloading = false;
            if (res.success) {
              this.$message.success("保存成功");
              this.$refs.passwordInfo.resetFields();
            }
          });
        }
      });
    },
    getCurrentAccount(flag) {
      //获取用户列表
      getCurrentAccount().then((res) => {
        if (res.success) {
          this.userInfo.id = res.result.id;
          this.userInfo.nickName = res.result.nickName;
          this.userInfo.mobile = res.result.mobile ? res.result.mobile : "";
          this.userInfo.path = res.result.path ? res.result.path : "";
          localStorage.setItem("nickName", this.userInfo.nickName);
          localStorage.setItem("mobile", this.userInfo.mobile);
          if (flag) {
            this.$store.dispatch("upDateAvatar");
            this.$store.dispatch("upDateUserName");
            this.$store.dispatch("upDateMobile");
          }
          if (res.result.avatar) {
            this.imageUrl = this.baseUrl + res.result.avatar;
            this.userInfo.avatar = res.result.avatar;
            localStorage.setItem("avatar", this.imageUrl);
          } else {
            this.imageUrl = localStorage.getItem("avatar");
          }
        }
      });
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
      this.userInfo.avatar = res.result;
    },
    beforeAvatarUpload(file) {
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        this.$message.error("上传封面图片大小不能超过 5MB!");
      }
      return isLt5M;
    },
    //获取当前用户可设置的主页列表
    getSysUserIndexOptions() {
      sysUserIndexOptions().then((res) => {
        this.optionsData = res.result.options;
      });
    },
  },
};
</script>
<style>
.tabs-box .el-tabs__nav-scroll {
  padding: 0 15px;
}
.avatar-uploader .el-upload {
  border: 2px solid #ededed;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  border-radius: 140px;
}

.avatar-uploader .el-upload:hover {
  border-color: #ff3f3f;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 140px;
  height: 140px;
  line-height: 140px;
  text-align: center;
}

.avatar {
  width: 140px;
  height: 140px;
  display: block;
}

.formFoo {
  width: 35%;
  margin: 0 auto 45px;
  padding-top: 20px;
}

.avatar-uploader {
  text-align: center;
}
.setPicture {
  text-align: center;
  line-height: 1;
  margin-bottom: 15px;
  color: #aab0b7;
}
.setPicture:hover {
  color: #ff3f3f;
}
.item .el-form-item__label {
  color: #e60012;
}
</style>
<style lang="scss" scoped>
.el-upload {
  .avatarBack {
    position: absolute;
    width: 100%;
    height: 100%;
    visibility: hidden;
    line-height: 140px;
    color: #fff;
    background: rgba(51, 51, 51, 0.4);
  }
  &:hover .avatarBack {
    visibility: visible;
  }
}
</style>

